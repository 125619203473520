import { COLORS } from '../../../../../core/styles/colors';
import { SITE_VARIABLES } from '../../../../../core/styles/variables';
// todo: Align all css class to one convention in the system
export const CSS_CLASSES = {
  MEDIA_OVERLAY: 'media-overlay',
  COMMENTS_ICON: '.comments-icon',
};

const CSS_SELECTORS = {
  HOVER: '&:hover',
};

export const editorCoverStyles = {
  container: {
    marginBottom: SITE_VARIABLES.MARGIN.HUGE,
    padding: `0 ${SITE_VARIABLES.PADDING.HUMONGOUS}`,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [`${CSS_SELECTORS.HOVER} ${[CSS_CLASSES.COMMENTS_ICON]}`]: {
      visibility: 'visible',
    },
  },
  [`${CSS_CLASSES.COMMENTS_ICON}`]: {
    visibility: 'hidden',
  },
  hiddenCoverContainer: {
    display: 'none',
  },
  visibleCoverContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      [`.${CSS_CLASSES.MEDIA_OVERLAY}`]: {
        display: 'flex',
      },
    },
  },
  coverWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '640px',
    maxWidth: '100%',
    height: '360px',
    padding: SITE_VARIABLES.PADDING.TINY,
    marginBottom: SITE_VARIABLES.MARGIN.TINY,
    border: `1px solid ${COLORS.SECONDARY15}`,
    borderRadius: '4px',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  captionWrapper: {
    alignSelf: 'flex-start',
  },
  videoIndicatorIcon: {
    default: {
      position: 'absolute',
      top: '16px',
      left: '16px',
      width: '32px',
      margin: SITE_VARIABLES.MARGIN.BIGGER,
      svg: {
        width: '100%',
        color: COLORS.WHITE,
      },
    },
  },
  migratedCoverContainer: {
    padding: `${SITE_VARIABLES.PADDING.MEDIUM} 0`,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [`${CSS_SELECTORS.HOVER} ${[CSS_CLASSES.COMMENTS_ICON]}`]: {
      visibility: 'visible',
    },
  },
};
