import { MenuReportsIcon, MenuSettingsIcon } from '@ftbpro/mm-admin-assets';
import { VMS_ROUTES_SCHEMA } from 'containers/vms/vms.constants';
import { CONTRIBUTORS_ANALYTICS_ROUTES } from 'containers/contributorsAnalytics/analytics.constants';
import { GlobalRole } from 'core/store/user/roles.constants.hq';
import { VoltaxRole } from 'core/store/user/roles.constants';
import EditorIcon from '../../ui/assets/menu/icon-menu-editor.inline.svg';
import VmsIcon from '../../ui/assets/menu/icon-menu-vms.inline.svg';
import CraftIcon from '../../ui/assets/menu/icon-menu-craft.inline.svg';
import ReportsIcon from '../../ui/assets/menu/icon-menu-reports.inline.svg';
import MediaLibraryIcon from '../../ui/assets/menu/icon-menu-media-library.inline.svg';
import { CRAFT_ROUTES } from '../../containers/craft/craftRouting.constants';
import { CMS_ROUTES } from '../../containers/cms/editor/utils/cmsRouter.constants';
import { ADMINISTRATION_ROUTES } from '../../containers/administration/administration.constants';
import { MEDIA_LIBRARY_ROUTES } from '../../containers/mediaLibrary/constants/mediaLibraryRouter.constants';
import { ANALYTICS_ROUTES } from '../../containers/reports/reports.constants';

const {
  CmsWriter,
  CmsEditor,
  CmsManager,
  CraftAdmin,
  CraftManager,
  VmsBrowser,
  VmsEditor,
  VmsExplorer,
  VmsManager,
  OrganizationAdmin,
  ContentEnrichmentAdmin,
  MediaAdmin,
  MediaContributor,
  QueryBuilderViewer,
} = VoltaxRole;

export const AvailableNavigationItems = [
  {
    title: 'Homepage',
    url: ANALYTICS_ROUTES.HOMEPAGE,
    requiredPermissions: [VoltaxRole.AnalyticsDashboardsViewer],
  },
  {
    title: 'CMS',
    icon: EditorIcon,
    subItems: [
      {
        title: 'Editor',
        url: CMS_ROUTES.EDITOR_DASHBOARD,
        requiredPermissions: [CmsEditor, CmsWriter],
      },
      {
        title: 'Publisher',
        url: CMS_ROUTES.ECHO_DASHBOARD,
        requiredPermissions: [CmsEditor, CmsManager],
      },
      {
        title: 'Schedule',
        url: CMS_ROUTES.ECHO_SCHEDULING,
        requiredPermissions: [CmsEditor],
      },
      {
        title: 'Enrichment',
        url: CMS_ROUTES.CONTENT_ENRICHMENT_DASHBOARD,
        requiredPermissions: [ContentEnrichmentAdmin],
      },
    ],
  },
  {
    title: 'Media Library',
    icon: MediaLibraryIcon,
    requiredPermissions: [MediaAdmin, MediaContributor],
    subItems: [{
      title: 'Images',
      url: MEDIA_LIBRARY_ROUTES.IMAGES,
      requiredPermissions: [MediaAdmin, MediaContributor],
    }],
  },
  {
    title: 'VMS',
    icon: VmsIcon,
    requiredPermissions: [VmsBrowser, VmsEditor, VmsManager, VmsExplorer],
    subItems: [
      {
        title: 'Browse Videos',
        url: VMS_ROUTES_SCHEMA.NETWORKVIDEOS,
        requiredPermissions: [VmsBrowser],
      },
      {
        title: 'Videos',
        url: VMS_ROUTES_SCHEMA.VIDEOS,
        requiredPermissions: [VmsEditor, VmsManager, VmsExplorer],
      },
      {
        title: 'Playlists',
        url: VMS_ROUTES_SCHEMA.PLAYLISTS,
        requiredPermissions: [VmsEditor, VmsManager, VmsExplorer],
      },
      {
        title: 'Players',
        url: VMS_ROUTES_SCHEMA.PLAYERS,
        requiredPermissions: [VmsManager, VmsExplorer],
      },
      {
        title: 'Imports',
        url: VMS_ROUTES_SCHEMA.IMPORTS,
        requiredPermissions: [VmsManager, VmsExplorer],
      },
      {
        title: 'Restrictions',
        url: VMS_ROUTES_SCHEMA.RESTRICTIONS,
        requiredPermissions: [VmsManager],
      },
    ],
  },
  {
    title: 'Craft',
    icon: CraftIcon,
    requiredPermissions: [CraftManager],
    subItems: [
      {
        title: 'Feeds',
        url: CRAFT_ROUTES.CRAFT_FEEDS,
        requiredPermissions: [CraftManager, CraftAdmin],
      },
      {
        title: 'Pages',
        url: CRAFT_ROUTES.CRAFT_PAGES,
        requiredPermissions: [CraftManager, CraftAdmin],
      },
      {
        title: 'Authors',
        url: CRAFT_ROUTES.CRAFT_AUTHORS,
        requiredPermissions: [CraftManager, CraftAdmin],
      },
      {
        title: 'Categories',
        url: CRAFT_ROUTES.CRAFT_CATEGORIES,
        requiredPermissions: [CraftManager, CraftAdmin],
      },
      {
        title: 'Layouts',
        url: CRAFT_ROUTES.CRAFT_LAYOUTS,
        requiredPermissions: [CraftAdmin],
      },
      {
        title: 'Navigations',
        url: CRAFT_ROUTES.CRAFT_NAVIGATIONS,
        requiredPermissions: [CraftAdmin],
      },
    ],
  },
  {
    title: 'Analytics',
    icon: ReportsIcon,
    subItems: [
      {
        title: 'Explore',
        url: ANALYTICS_ROUTES.QUERY_BUILDER,
        requiredPermissions: [QueryBuilderViewer],
      },
      {
        title: 'Dashboards',
        subItems: [
          {
            title: 'Total Revenue Overview',
            url: ANALYTICS_ROUTES.REVENUE_OVERVIEW_DASHBOARD,
            requiredPermissions: [VoltaxRole.AnalyticsDashboardsViewer],
          },
          {
            title: 'Voltax Player Revenue',
            url: ANALYTICS_ROUTES.VOLTAX_REVENUE_DASHBOARD,
            requiredPermissions: [VoltaxRole.AnalyticsDashboardsViewer],
          },
          {
            title: 'MM SSP Revenue',
            url: ANALYTICS_ROUTES.MM_SSP_DASHBOARD,
            requiredPermissions: [VoltaxRole.AnalyticsDashboardsViewer],
          },
          {
            title: 'Unified Player Revenue',
            url: ANALYTICS_ROUTES.UNIFIED_PLAYER_REVENUE_DASHBOARD,
            requiredPermissions: [VoltaxRole.UnifiedPlayerDashboardViewer],
          },
          {
            title: 'Total Revenue Summary',
            url: ANALYTICS_ROUTES.REVENUE_OVERVIEW_SUMMARY_DASHBOARD,
            requiredPermissions: [VoltaxRole.UnifiedPlayerDashboardViewer],
          },
        ],
      }],
  },
  {
    title: 'Contributors Analytics',
    icon: MenuReportsIcon,
    requirePermissions: [VoltaxRole.FSMyStatsViewer],
    subItems: [
      {
        title: 'Site Stats',
        url: CONTRIBUTORS_ANALYTICS_ROUTES.SITE_STATS,
        requiredPermissions: [VoltaxRole.FSSiteStatsViewer],
      },
      {
        title: 'My Stats',
        url: CONTRIBUTORS_ANALYTICS_ROUTES.USER_STATS,
        requiredPermissions: [VoltaxRole.FSMyStatsViewer],
      },
    ],
  },
  {
    title: 'Administration',
    icon: MenuSettingsIcon,
    requiredPermissions: [OrganizationAdmin, GlobalRole.MegaAdmin],
    subItems: [
      {
        title: 'Users',
        url: ADMINISTRATION_ROUTES.USERS,
        requiredPermissions: [OrganizationAdmin, GlobalRole.MegaAdmin],
      },
      {
        title: 'Audit',
        url: ADMINISTRATION_ROUTES.AUDIT,
        requiredPermissions: [OrganizationAdmin, GlobalRole.MegaAdmin],
      },
    ],
  },
];
