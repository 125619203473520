import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import 'react-image-crop/dist/ReactCrop.css';

import { IconButton, ButtonType, ImagePreview, Loader, RadioGroup, Text } from '@ftbpro/mm-admin-ui-components';

import { ChevronLeftIcon } from '@ftbpro/mm-admin-assets';
import { useStyles } from '../../../../../core/hooks/useStyles';

import { CropImageTitle } from './CropImageTitle';

import { CROP_OPTIONS, ORIGINAL_ASPECT } from '../../../services/imageServices/imageService.utils';

import { getStylesObject, radioVariables } from './styles/croppingArea.styles';
import { IMAGE_REQUIREMENTS } from '../../../services/imageServices/imageService.constants';

export const CroppingAreaCustomization = ({
  imageLoading,
  hasImage,
  src,
  percentageCrop,
  backButton = false,
  shouldAllowHorizontalCropOnly,
  backButtonOnClick,
  setCompletedCrop,
  onCropAspectTypeChange,
  onImageLoaded,
  shouldRenderCropImageTitle = true,
  hasOriginalSize = false,
}) => {
  const styles = useStyles(getStylesObject, [imageLoading, hasImage]);
  const { aspect, ...cropData } = percentageCrop;

  const getCroppingTypeSelectorComponentIfAvailable = () => {
    const cropOptions = hasOriginalSize ? [...CROP_OPTIONS, ORIGINAL_ASPECT] : CROP_OPTIONS;

    return (
      shouldAllowHorizontalCropOnly
        ? null
        : (
          <div css={styles.cropTypeContainer}>
            <RadioGroup
              items={cropOptions}
              checkedValue={aspect}
              onCheckChanged={onCropAspectTypeChange}
              disabled={imageLoading}
              orientation={RadioGroup.ORIENTATION.HORIZONTAL}
              variables={hasOriginalSize ? radioVariables : {}}
            />
          </div>
        )
    );
  };

  const renderImageRequirements = () => (
    <div>
      {Object.values(IMAGE_REQUIREMENTS).map((val, index) => (
        <Text
          type={Text.TEXT_TYPES.PARAGRAPH_M}
          css={styles.imageRequirementsText}
          key={index}
        >
          {val}
        </Text>
      ))}
    </div>
  );

  const shouldRenderCropAreaHeader = () => {
    return hasImage && (backButton || shouldRenderCropImageTitle);
  };

  return (
    <Fragment>
      {shouldRenderCropAreaHeader() && (
        <Fragment>
          <div css={styles.cropAreaHeader}>
            {backButton && (
              <IconButton
                icon={ChevronLeftIcon}
                width="32px"
                height="32px"
                type={ButtonType.Primary}
                onClick={backButtonOnClick}
                style={{ marginRight: '8px' }}
              />
            )}
            {shouldRenderCropImageTitle && (
              <CropImageTitle />
            )}
          </div>
          {getCroppingTypeSelectorComponentIfAvailable()}
        </Fragment>
      )}
      {imageLoading ? <div css={styles.loaderWrapper}><Loader /></div> : null}
      {!hasImage && !imageLoading ? renderImageRequirements() : null}
      <div css={css(styles.reactCropWrapper)}>
        <ImagePreview
          src={src}
          enableCrop
          cropConfig={{
            percentageCrop: cropData,
            aspect,
            onCropComplete: setCompletedCrop,
            minHeight: 50,
            minWidth: 50,
          }}
          onImageLoaded={onImageLoaded}
        />
      </div>
    </Fragment>
  );
};
