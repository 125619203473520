import React, { Fragment, useEffect, useMemo } from 'react';
import { Filter, Types } from '@ftbpro/mm-admin-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { navigationFiltersSelector } from 'core/store/navigationFilters/navigationFilters.selector';
import { Product } from 'core/constants';
import { userSelector } from 'core/store/user/user.selector';
import { getCurrentPropertyLocalStorageKey, getFirstOrLastSelectedProperty } from 'core/store/property/property.utils';
import { getAvailablePropertiesNames, getPropertiesByRoles, findPropertyByName, findPropertyBySlug } from './navigationFilters.utils';
import { updateCurrentProperty } from '../../store/property/propertySlice';
import { propertySelector } from '../../store/property/property.selector';

import { FILTER_VARIABLES } from './navigationFilters.styles';

const PLACEHOLDER = 'Property';

export const VoltaxPropertyFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const product = useSelector(navigationFiltersSelector.product) as Product;
  const preSelectedProperty = useSelector(navigationFiltersSelector.preSelectedProperty) || '';
  const disabled = useSelector(navigationFiltersSelector.disablePropertySelection);
  const voltaxUserPremissions = useSelector(userSelector.getVoltaxUserPermission);
  const currentProperty = useSelector(state => propertySelector.currentProperty(state, product));
  const userId = useSelector(userSelector.getUserId);
  const allowedRoles = useSelector(navigationFiltersSelector.allowedRoles);
  const organizationProperties = useSelector(propertySelector.organizationProperties);

  const propertiesForAllowedRoles = useMemo(() => {
    if (voltaxUserPremissions && allowedRoles) {
      return getPropertiesByRoles(allowedRoles, voltaxUserPremissions, organizationProperties);
    }
    return [];
  }, [voltaxUserPremissions, allowedRoles]);

  useEffect(() => {
    if (!isEmpty(propertiesForAllowedRoles)) {
      const preset = findPropertyBySlug(propertiesForAllowedRoles, preSelectedProperty);
      const defaultProperty = getFirstOrLastSelectedProperty(propertiesForAllowedRoles, getCurrentPropertyLocalStorageKey(userId, product));
      dispatch(updateCurrentProperty({
        selectedProperty: preset || defaultProperty,
        product,
        userId,
      }));
    }
  }, [dispatch, propertiesForAllowedRoles, preSelectedProperty, product]);

  const onPropertyChange = (e: Types.ClickEventType, { value: newProperty } :{value: string | number}) => {
    const selectedProperty = findPropertyByName(propertiesForAllowedRoles, newProperty as string);
    dispatch(updateCurrentProperty({ selectedProperty, product, userId }));
  };

  return (
    <Fragment>
      <Filter
        placeholder={PLACEHOLDER}
        items={getAvailablePropertiesNames(propertiesForAllowedRoles)}
        disabled={disabled}
        onSelectedChange={onPropertyChange}
        selectedValue={currentProperty?.name}
        variables={FILTER_VARIABLES}
      />
    </Fragment>
  );
};
